<template>
<div class="webview" id="webview">
    <van-list
      v-model="loading"
      :finished="finished"
      :offset="offset"
      :finished-text="api_access_token != '' && api_access_token != undefined  && api_access_token != null?'没有更多':''"
      @load="onLoad"
    >
    <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
      <van-swipe-item  v-for="(item,index) in imgList" :key="index">
        <img :src="item.face" alt="" class="carousel-img" @click="goToUrl(item)">
      </van-swipe-item>
    </van-swipe>
    <!-- <el-carousel height="170px" :interval="4000">
        <el-carousel-item v-for="(item,index) in imgList" :key="index">
         <img :src="item.face" alt="" class="carousel-img" @click="goToUrl(item)">
      </el-carousel-item>
    </el-carousel> -->
    <div class="list" v-if="api_access_token != '' && api_access_token != undefined  && api_access_token != null ">
      <div class="show-list">
        <div class="list-item flex-start" style="justify-content:space-between;" v-for="(item,index) in listForm"  :key="index" @click="goToUrl(item)">
            <img :src="item.face" alt="图片" class="list-img"  v-if="item.face !=''">
            <div class="list-msg" v-if="item.title !=''">
              <div class="list-title">{{item.title}}</div>
              <div class="list-tips fz12">{{item.introduce}}</div>
            </div>
            <div class="lin"></div>
        </div>
      </div>
    </div>
  </van-list>
</div>
</template>

<script>
import {checkInfoModel} from '@/api/workbench'
import Swiper from 'swiper';
export default {
name: "webView",
  data(){
    return{
      imgList:[],
      showList:false,
      judge:true,
      listForm:[],
      page:1,
      webview:'',
      loading:false,
      finished:false, // 滚动加载完成
      pageSize: 10, // 分页大小
      total: 0, // 查询总条数
      offset:0,//滚动条与底部距离小于 offset 时触发load事件，默认300
      api_access_token:'',
    }
  },
  created() {
    this.initFunction()
  },
  mounted(){
    new Swiper ('.swiper-container', {
        loop: true,
        // 如果需要分页器
        pagination: '.swiper-pagination',
        // 如果需要前进后退按钮
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        // 如果需要滚动条
        scrollbar: '.swiper-scrollbar',
    })        
  },
  methods:{
    initFunction(){
      let appid = this.GetQueryString('appid')
      let id  = this.GetQueryString('id')
      let api_access_token =  this.GetQueryString('api_access_token')
      this.api_access_token = api_access_token
      let userid =  this.GetQueryString('userid')
      this.initModel(appid,id,api_access_token,userid)
      this.initList(appid,id,api_access_token,userid)
      this.webview = document.getElementById('webview');
      // if(this.judge == true){
      //     this.webview.addEventListener('scroll', this.lazyLoading)
      // }
    },
    GetQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.hash.substr(1).match(reg); 
      if (r != null) return unescape(r[2]); return null; 
    },
    initModel(appid,id,api_access_token,userid){
      checkInfoModel({wv_type:'carousel',appid:appid,id:id,api_access_token:api_access_token,userid:userid}).then(res=>{
        this.imgList=res.data.init_data
      })
    },
    initList(appid,id,api_access_token,userid){
       this.loading =  true
       checkInfoModel({wv_type:'list',appid:appid,id:id,api_access_token:api_access_token,userid:userid,page:this.page,limit:this.pageSize}).then(res=>{
        res.data.init_data.forEach(item=>{
          this.listForm.push(item)
        })
        if(res.data.init_data.length == 10){
              this.finished = false;
          }else{
              this.finished = true;
        }
        this.loading =  false
      })
    },
    goToUrl(item){
      if(item.is_jump == 1){
        window.open(item.jump_url)
      }
    },
    // 滚动底部加载数据
    onLoad () { 
        this.loading = true;
        this.page = this.page + 1
        this.initFunction()
      }
  }
}
</script>

<style lang="less" scoped>
.webview{
  height: 170px;
  .carousel-img{
    width: 100%;
    height: 170px;
  }
}
//  .swiper-container {
//         width: 500px;
//         height: 300px;
//         margin: 20px auto;
//     }
.list{
   height:100%;
   background:#F5F5F5;
  .show-list{
    // height: 140px;
    width: 100%;
    background: #fff;
    
    .list-item{
      padding: 8px 20px;
      border-bottom:1px solid #F0F0F0;
      .list-img{
        width:78px;
        height: 58px;
        overflow:hidden;
        // margin-left: 15px;
      }
      .list-msg{
        width:240px;
        height:78.5px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        .list-tips,.list-title{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color:#333333;
          font-size: 14px;
          font-weight:500;
        }
        .list-title{
          margin-bottom: 5px;
          color:#333333;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }

}
</style>